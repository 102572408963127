<template>
  <v-card class="mb-3">
    <v-list class="py-0" two-line>
      <v-list-group v-for="(content, i) in contents" :key="i">
        <template #activator>
          <v-list-tile>
            <v-list-tile-content>
              <v-list-tile-title class="font-weight-medium">
                {{ content.question }}
              </v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>
        </template>
        <v-card>
          <v-card-text>{{ content.answer }}</v-card-text>
          <template v-if="content.tourUrl">
            <v-btn
              color="primary"
              class="elevation-0 mb-3"
              large
              @click="showTour(content.tourUrl)"
            >
              <v-icon left>play_circle_outline</v-icon>
              Start Tutorial
            </v-btn>
          </template>
          <template v-if="content.videoUrl">
            <v-btn
              color="warning"
              class="elevation-0 mb-3"
              large
              @click="openVideo()"
            >
              <v-icon left>videocam</v-icon>
              Watch Video
            </v-btn>
            <VideoDialog
              v-model="showVideo"
              :video-url="content.videoUrl"
              @hide-video="showVideo = false"
            />
          </template>
        </v-card>
      </v-list-group>
    </v-list>
  </v-card>
</template>

<script>
import VideoDialog from "@/components/global/VideoDialog";
import { mapActions } from "vuex";

export default {
  name: "FAQPanel",
  components: {
    VideoDialog,
  },
  props: {
    contents: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      showVideo: false,
    };
  },
  watch: {
    value: {
      handler() {
        this.open = this.value;
      },
    },
  },
  methods: {
    ...mapActions("statistics", ["beginTour"]),
    openVideo() {
      this.showVideo = true;
    },
    hideVideo() {
      this.showVideo = false;
    },
    showTour(tourUrl) {
      this.beginTour();
      this.$router.push(tourUrl);
    },
  },
};
</script>

<style lang="scss" scoped>
.panel-body {
  font-style: italic;
}

.video-container {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  height: 0;
  overflow: hidden;
}

.video-container iframe,
.video-container object,
.video-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 50%;
}
</style>
