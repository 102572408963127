<template>
  <v-form class="login" @submit.prevent="requestEmailSignup">
    <h1 class="text-xs-center mb-3">Grow your network the right way.</h1>
    <v-layout row>
      <v-btn
        color="#4285F4"
        large
        dark
        block
        min-width="600"
        class="pl-1 pr-3 mb-3"
        @click="requestGoogleSignup"
      >
        <img src="@/assets/google-logo.png" />
        <span class="button-label">Sign Up with Google</span>
      </v-btn>
    </v-layout>
    <v-layout row>
      <v-btn
        color="#D53A00"
        class="pl-1 pr-3"
        large
        dark
        block
        @click="requestMicrosoftSignup"
      >
        <img src="@/assets/office-logo.png" />
        <span class="button-label">Sign Up with Office 365</span>
      </v-btn>
    </v-layout>
    <DividerBar text="OR" />
    <p class="card-title mb-4 text-xs-center">Sign Up With Email</p>
    <v-text-field
      v-model="name"
      :rules="[rules.required]"
      type="text"
      label="Name"
      placeholder="Your Name"
      required
      solo
    />
    <v-text-field
      v-model="email"
      :rules="[rules.required]"
      autocomplete="username"
      type="email"
      label="E-mail"
      placeholder="you@example.com"
      required
      solo
    />
    <v-text-field
      v-model="password"
      :rules="[rules.required, rules.min]"
      autocomplete="current-password"
      label="Password"
      hint="At least 8 characters"
      type="password"
      required
      solo
    />
    <v-text-field
      v-model="passwordConfirm"
      :rules="[rules.required, rules.min]"
      autocomplete="current-password"
      label="Re-enter Password"
      hint="Must match the above password"
      type="password"
      required
      solo
    />
    <v-btn
      type="submit"
      color="primary"
      large
      block
      :disabled="!isValidFormData"
    >
      Create Account
    </v-btn>
    <p class="text-xs-center mt-3">
      Have an account?
      <router-link :to="loginRoute">Log in.</router-link>
    </p>
  </v-form>
</template>

<script>
import DividerBar from "@/components/DividerBar";

export default {
  name: "SignupForm",
  components: {
    DividerBar,
  },
  data() {
    return {
      email: "",
      name: "",
      password: "",
      passwordConfirm: "",
      rules: {
        required: (value) => !!value || "Required",
        min: (v) => v.length >= 8 || "Min 8 characters",
      },
    };
  },
  computed: {
    loginRoute() {
      let redirectTo = this.$route.query.from;
      if (redirectTo === undefined) {
        return { path: "/login" };
      } else {
        return { path: "/login", query: { from: redirectTo } };
      }
    },
    isValidFormData() {
      return (
        this.email !== "" &&
        this.name !== "" &&
        this.password !== "" &&
        this.passwordConfirm !== "" &&
        this.password.length >= 8 &&
        this.password === this.passwordConfirm
      );
    },
  },
  methods: {
    requestEmailSignup() {
      this.$emit("sign-up-email", {
        email: this.email,
        name: this.name,
        password: this.password,
      });
    },
    requestGoogleSignup() {
      this.$emit("sign-up-google");
    },
    requestMicrosoftSignup() {
      this.$emit("sign-up-microsoft");
    },
  },
};
</script>

<style lang="scss" scoped>
.button-logo {
  position: absolute;
  left: 8px;
}

.button-label {
  width: 100%;
}

div.btn__content {
  background-color: #f00 !important;
  margin: 16px;
}
</style>
