<template>
  <v-layout row wrap justify-end>
    <v-btn flat small color="primary" class="ma-0 pa-0" @click="clearFilter">
      clear
    </v-btn>
    <v-flex xs12 py-0>
      <v-text-field
        v-model="name"
        class="py-0"
        label="Name"
        maxlength="150"
        @keyup.enter.native="addSimpleFilter"
      />
    </v-flex>
    <v-flex xs12 py-0>
      <v-text-field
        v-model="jobTitle"
        class="py-0"
        label="Job Title"
        maxlength="150"
        @keyup.enter.native="addSimpleFilter"
      />
    </v-flex>
    <v-flex xs12 py-0>
      <v-text-field
        v-model="company"
        class="py-0"
        label="Company"
        maxlength="150"
        @keyup.enter.native="addSimpleFilter"
      />
    </v-flex>
    <v-flex py-0 xs12>
      <v-select
        v-model="currentOption"
        label="Show results for"
        :items="currentOptions"
        item-text="label"
        item-value="value"
      />
    </v-flex>
    <v-flex py-0 xs12>
      <v-btn
        class="my-0 elevation-0"
        color="primary white--text"
        block
        @click="addSimpleFilter"
      >
        <v-icon class="white--text" left> search </v-icon>
        Search
      </v-btn>
    </v-flex>
    <v-flex py-0 xs12>
      <v-btn block flat color="primary" @click="toggleSearchMode">
        Advanced Search
      </v-btn>
    </v-flex>
  </v-layout>
</template>

<script>
export default {
  name: "AddSimpleFilter",
  data() {
    return {
      name: "",
      jobTitle: "",
      company: "",
      currentOption: true,
      currentOptions: [
        { label: "Only current information", value: true },
        { label: "Current and past information", value: null },
      ],
    };
  },
  computed: {
    canAdd() {
      return !(this.name == "" && this.jobTitle == "" && this.company == "");
    },
  },
  methods: {
    addSimpleFilter() {
      if (!this.canAdd) {
        return;
      }

      let filters = [
        {
          name: "name",
          nameLabel: "Name",
          value: this.name,
          valueLabel: this.name,
          where: "CONTAINS",
          whereLabel: "contains",
          current: this.currentOption,
        },
        {
          name: "job_title",
          nameLabel: "Job Title",
          value: this.jobTitle,
          valueLabel: this.jobTitle,
          where: "CONTAINS",
          whereLabel: "contains",
          current: this.currentOption,
        },
        {
          name: "company",
          nameLabel: "Company",
          value: this.company,
          valueLabel: this.company,
          where: "CONTAINS",
          whereLabel: "contains",
          current: this.currentOption,
        },
      ];

      this.$emit(
        "add-simple-filter",
        filters.filter((filter) => filter.value != ""),
      );
    },
    clearFilter() {
      this.reset();
      this.$emit("clear-filter");
    },
    toggleSearchMode() {
      this.reset();
      this.$emit("toggle-search-mode");
    },
    reset() {
      (this.name = ""), (this.jobTitle = ""), (this.company = "");
    },
  },
};
</script>
