<template>
  <div class="toolbar">
    <v-toolbar
      v-if="$vuetify.breakpoint.smAndDown"
      color="navy"
      class="elevation-0"
      height="80"
      app
      dark
    >
      <router-link to="/connections" class="mt-1">
        <img width="44" src="@/assets/logo-circle.png" />
      </router-link>
      <v-spacer />
      <v-btn
        v-if="needsToShowSubscribe"
        color="yellow lighten-1"
        outline
        dark
        @click="doSubscribe"
      >
        Subscribe
      </v-btn>
      <v-spacer />
      <v-toolbar-items>
        <v-btn class="ma-0" large icon @click="showMobileMenu = true">
          <v-icon>menu</v-icon>
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>
    <v-toolbar
      v-if="$vuetify.breakpoint.mdAndUp"
      color="navy"
      class="elevation-0"
      app
      dark
    >
      <router-link to="/connections" class="mr-3">
        <img width="44" src="@/assets/logo-circle.png" />
      </router-link>
      <v-toolbar-items>
        <v-btn to="/connections" flat dark>Connections</v-btn>
        <v-btn to="/contacts" flat dark>My Contacts</v-btn>
        <v-btn to="/teams" flat dark>Teams</v-btn>
        <v-btn to="/import" flat dark>Build Your Score</v-btn>
      </v-toolbar-items>
      <v-spacer></v-spacer>
      <v-btn
        v-if="needsToShowSubscribe"
        color="yellow lighten-1"
        outline
        dark
        @click="doSubscribe"
      >
        Subscribe
      </v-btn>
      <v-toolbar-items>
        <v-btn to="/faq" icon flat dark>
          <v-icon>help</v-icon>
        </v-btn>
        <v-menu offset-y open-on-hover>
          <template #activator="{ on }">
            <v-btn class="elevation-0" flat v-on="on">
              {{ profile.email }}
              <v-icon right>arrow_drop_down</v-icon>
            </v-btn>
          </template>
          <v-list class="py-0 navy" dark>
            <v-list-tile to="/profile">
              <v-list-tile-avatar>
                <v-icon left>account_circle</v-icon>
              </v-list-tile-avatar>
              <v-list-tile-content>
                <v-list-tile-title class="caption">PROFILE</v-list-tile-title>
              </v-list-tile-content>
            </v-list-tile>
            <v-list-tile v-if="needsToShowBill" to="/billing">
              <v-list-tile-avatar>
                <v-icon left>payment</v-icon>
              </v-list-tile-avatar>
              <v-list-tile-content>
                <v-list-tile-title class="caption">BILLING</v-list-tile-title>
              </v-list-tile-content>
            </v-list-tile>
            <v-list-tile @click="requestLogout">
              <v-list-tile-avatar>
                <v-icon left>exit_to_app</v-icon>
              </v-list-tile-avatar>
              <v-list-tile-content>
                <v-list-tile-title class="caption">LOGOUT</v-list-tile-title>
              </v-list-tile-content>
            </v-list-tile>
          </v-list>
        </v-menu>
      </v-toolbar-items>
    </v-toolbar>
    <v-dialog
      v-model="showMobileMenu"
      transition="dialog-bottom-transition"
      fullscreen
    >
      <MobileAppMenu
        v-if="showMobileMenu"
        :profile="profile"
        :needs-to-show-bill="needsToShowBill"
        :needs-to-show-subscribe="needsToShowSubscribe"
        @close="showMobileMenu = false"
        @do-subscribe="doSubscribe"
      />
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import MobileAppMenu from "@/components/MobileAppMenu";
import PaymentApi from "@/api/payment";
import whitelabelConfig from "@/whitelabel.config";

export default {
  name: "AppHeader",
  components: {
    MobileAppMenu,
  },
  data() {
    return {
      showChangeTeam: false,
      showMobileMenu: false,
    };
  },
  computed: {
    ...mapGetters({
      paymentProfile: "payment/getPaymentProfile",
      profile: "getProfile",
    }),
    needsToShowBill() {
      if (this.paymentProfile) {
        return !this.paymentProfile.billing_exempt;
      }
      return false;
    },
    needsToShowSubscribe() {
      if (!this.paymentProfile) {
        return false;
      }
      if (this.paymentProfile.billing_exempt) {
        return false;
      }

      // status should be: active / cancelled / no_subscription
      let status = this.paymentProfile.subscription_status;
      if (status === "active") {
        return false;
      }
      return true;
    },
  },
  mounted() {
    this.fetchPaymentProfile();
    this.fetchProfile();
  },
  methods: {
    ...mapActions({
      fetchPaymentProfile: "payment/fetchPaymentProfile",
      fetchProfile: "fetchProfile",
      logout: "logout",
    }),
    doSubscribe() {
      PaymentApi.fetchCheckoutSessionId()
        .then((resp) => {
          this.redirectToStripePage(resp.data);
        })
        .catch((_error) => {});
    },
    getWhiteLabelConfig() {
      return whitelabelConfig[process.env.VUE_APP_BRAND];
    },
    redirectToStripePage(sessionId) {
      // Redirect to Stripe page showing 6DOS plan with a session id
      let stripe = window.Stripe(
        this.getWhiteLabelConfig().stripePublishableKey,
      );
      stripe
        .redirectToCheckout({ sessionId: sessionId })
        .then(function (result) {
          if (result.error) {
            this.$notify({
              group: "notifs",
              title: "Payment Request Failed",
              text: result.error.message,
              type: "error",
            });
          }
        });
    },
    requestLogout() {
      this.logout().then(() => {
        this.$router.push("/login");
      });
    },
  },
};
</script>
