<template>
  <v-container grid-list-lg fluid>
    <AppHeader />
    <v-content fill-height>
      <v-layout row justify-center>
        <v-flex xs12 md8>
          <v-layout row wrap>
            <v-flex xs12>
              <v-breadcrumbs class="pa-0" :items="breadcrumbItems" divider="/">
                <template v-slot:item="props">
                  <router-link
                    :to="props.item.to"
                    v-text="props.item.text"
                    class="v-breadcrumbs__item"
                  />
                </template>
              </v-breadcrumbs>
            </v-flex>
          </v-layout>
          <v-layout row wrap>
            <v-flex grow>
              <PageTitle value="Merge & Fix" />
            </v-flex>
            <v-flex shrink>
              <v-btn
                v-if="duplicates.length > 0"
                :loading="isLoading"
                class="primary"
                large
                dark
                @click="confirmMergeDialog = true"
              >
                <v-icon left>done_all</v-icon>
                Merge
              </v-btn>
            </v-flex>
          </v-layout>
          <v-layout v-if="totalPages > 1" row justify-center>
            <v-flex shrink>
              <v-pagination
                v-model="page"
                :length="totalPages"
                prev-icon="chevron_left"
                next-icon="chevron_right"
                total-visible="7"
                circle
              />
            </v-flex>
          </v-layout>
          <v-layout
            v-if="isLoadingInitial"
            row
            wrap
            fill-height
            justify-center
            align-center
          >
            <v-flex xs12 class="d-flex justify-center align-center">
              <v-progress-circular
                color="primary"
                indeterminate
                size="128"
              ></v-progress-circular>
            </v-flex>
          </v-layout>
          <v-layout
            v-if="!(isLoadingInitial || hasDuplicates)"
            align-center
            fill-height
            justify-center
            row
            wrap
          >
            <v-flex class="text-xs-center grey--text" xs12 md7 lg5 xl4>
              <v-icon color="grey" size="96">done</v-icon>
              <br />
              <p
                v-if="$vuetify.breakpoint.mdAndUp"
                class="display-3 font-weight-thin font-italic"
              >
                No Duplicates
              </p>
              <p v-else class="display-1 font-weight-thin font-italic">
                No Duplicates
              </p>
              <v-btn
                class="mt-4"
                color="primary"
                large
                block
                @click="$router.push('/contacts')"
              >
                Back to Contacts
              </v-btn>
            </v-flex>
          </v-layout>
          <v-layout
            v-for="(dup, index) in resultsPage"
            :key="index"
            row
            justify-center
            align-center
          >
            <v-flex xs2>
              <v-btn-toggle
                v-model="selections[(page - 1) * pageSize + index]"
                class="response-toggle"
                mandatory
                block
              >
                <v-btn
                  active-class="primary white--text"
                  class="black--text pa-1"
                  block
                  flat
                  large
                >
                  Accept
                </v-btn>
                <v-btn
                  active-class="navy white--text"
                  class="black--text pa-1"
                  block
                  flat
                  large
                >
                  Reject
                </v-btn>
              </v-btn-toggle>
            </v-flex>
            <v-flex xs10>
              <v-card>
                <v-data-table
                  :headers="headers"
                  :items="dup"
                  item-key="contact_id"
                  hide-actions
                >
                  <template slot="items" slot-scope="props">
                    <tr @click="selectContact(props.item)">
                      <td class="pl-3">
                        <v-layout row align-center>
                          <v-flex shrink>
                            <Avatar
                              :username="props.item.name || 'N/A'"
                              :size="34"
                            />
                          </v-flex>
                          <v-flex>
                            <span>{{ props.item.name }}</span>
                          </v-flex>
                        </v-layout>
                      </td>
                      <td>{{ props.item.organization }}</td>
                      <td>{{ props.item.title }}</td>
                      <td>{{ getValueByType(props.item.info, "email") }}</td>
                      <td>
                        {{ getValueByType(props.item.info, "phone_number") }}
                      </td>
                      <td class="pr-0">
                        <font-awesome-icon
                          v-for="(source, index) in props.item.sources"
                          :key="index"
                          :icon="['fab', iconFor(source)]"
                          size="lg"
                          class="fa-fw mr-1"
                        />
                      </td>
                    </tr>
                  </template>
                </v-data-table>
              </v-card>
            </v-flex>
          </v-layout>
          <v-layout v-if="totalPages > 1" row justify-center>
            <v-flex shrink>
              <v-pagination
                v-model="page"
                :length="totalPages"
                prev-icon="chevron_left"
                next-icon="chevron_right"
                total-visible="7"
                circle
              />
            </v-flex>
          </v-layout>
          <v-layout row wrap>
            <v-flex grow> </v-flex>
            <v-flex shrink>
              <v-btn
                v-if="duplicates.length > 0"
                :loading="isLoading"
                class="primary"
                large
                dark
                @click="confirmMergeDialog = true"
              >
                <v-icon left>done_all</v-icon>
                Merge
              </v-btn>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-content>
    <ConfirmDialog
      v-model="confirmMergeDialog"
      :loading="isMerging"
      :text="confirmMergeText"
      title="Confirm Merge"
      @cancel="confirmMergeDialog = false"
      @confirm="submitResponses"
    />
    <ConnectionDetailDialog
      v-model="contactDetailDialog"
      :connection="selectedContact"
      @delete-connection="deleteConnection($event)"
      @close="closeConnectionDetail"
    />
  </v-container>
</template>

<script>
import AppHeader from "@/components/AppHeader.vue";
import Avatar from "vue-avatar";
import ConfirmDialog from "@/components/global/ConfirmDialog.vue";
import ConnectionDetailDialog from "@/components/connection/ConnectionDetailDialog";
import ContactApi from "@/api/contacts";
import PageTitle from "@/components/global/PageTitle.vue";
import {
  faGoogle,
  faApple,
  faMicrosoft,
  faLinkedin,
  faWindows,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";

library.add(faGoogle, faApple, faMicrosoft, faLinkedin, faWindows);

const faIcons = {
  google: "google",
  microsoft: "windows",
  linkedin: "linkedin",
  icloud: "apple",
  exchange: "microsoft",
};

export default {
  name: "DuplicatesView",
  components: {
    AppHeader,
    Avatar,
    ConfirmDialog,
    ConnectionDetailDialog,
    "font-awesome-icon": FontAwesomeIcon,
    PageTitle,
  },
  data: () => ({
    breadcrumbItems: [
      { text: "My Contacts", to: "/contacts" },
      { text: "Merge & Fix", to: "/contacts/duplicates" },
    ],
    confirmMergeDialog: false,
    contactDetailDialog: false,
    duplicates: [],
    headers: [
      {
        text: "Name",
        align: "left",
        value: "name",
        sortable: false,
      },
      {
        text: "Organization",
        align: "left",
        value: "organization",
        sortable: false,
      },
      {
        text: "Title",
        align: "left",
        value: "title",
        sortable: false,
      },
      {
        text: "Email",
        align: "left",
        value: "email",
        sortable: false,
      },
      {
        text: "Phone Number",
        align: "left",
        value: "phone",
        sortable: false,
      },
      {
        text: "Imported From",
        align: "right",
        value: "source",
        sortable: false,
        width: "10%",
      },
    ],
    isLoading: false,
    isLoadingInitial: false,
    isMerging: false,
    page: 1,
    pageSize: 50,
    selectedContact: { name: "Not Set." },
    selections: [],
    total: 0,
  }),
  computed: {
    confirmMergeText() {
      return `Are you sure you want to merge ${this.confirmMergeTotal} duplicates?`;
    },
    confirmMergeTotal() {
      return this.selectionsPage.filter((x) => x === 0).length;
    },
    hasDuplicates() {
      return this.total > 0;
    },
    resultsPage() {
      return this.duplicates.slice(
        (this.page - 1) * this.pageSize,
        this.page * this.pageSize,
      );
    },
    selectionsPage() {
      return this.selections.slice(
        (this.page - 1) * this.pageSize,
        this.page * this.pageSize,
      );
    },
    totalPages() {
      return Math.ceil(this.total / this.pageSize);
    },
  },
  async mounted() {
    await this.fetchContactDuplicates();
  },
  methods: {
    closeConnectionDetail() {
      this.contactDetailDialog = false;
      this.selectedContact = { name: "Not Set." };
    },
    deleteConnection(_contact_id) {
      this.closeConnectionDetail();
      this.$refs.contactTable.fetchContacts();
    },
    async fetchContactDuplicates() {
      this.isLoadingInitial = true;

      try {
        let resp = await ContactApi.fetchContactDuplicates();
        this.duplicates = resp.data;
        this.total = resp.total;
        this.selections = this.duplicates.map((_x) => 0);
      } catch (error) {
        this.$notify({
          group: "notifs",
          title: "Request Failed",
          text: "Something went wrong.",
          type: "error",
        });
      } finally {
        this.isLoadingInitial = false;
      }
    },
    getValueByType(infoArray, type, field = "value") {
      const item = infoArray.find((info) => info.type === type && info.current);
      return item ? item[field] : "";
    },
    iconFor(source) {
      return faIcons[source];
    },
    notifyError(msg = "If this issue persists, please contact support.") {
      this.$notify({
        group: "notifs",
        title: "Request Failed",
        text: msg,
        type: "error",
      });
    },
    selectContact(contact) {
      this.selectedContact = contact;
      this.contactDetailDialog = true;
    },
    async submitResponses() {
      this.isLoading = true;
      this.isMerging = true;
      let acceptIds = [];
      let rejectIds = [];

      this.selectionsPage.forEach((response, i) => {
        switch (response) {
          case 0:
            acceptIds.push(
              this.resultsPage[i].map((contact) => contact.contact_id),
            );
            break;
          case 1:
            rejectIds.push(
              this.resultsPage[i].map((contact) => contact.contact_id),
            );
            break;
          default:
            break;
        }
      });

      try {
        await ContactApi.bulkMergeContacts(acceptIds, rejectIds);

        // Eventually document re-indexing needs to be made synchronous
        // on the backend.
        setTimeout(() => {
          this.isLoading = false;
          this.isMerging = false;
          this.confirmMergeDialog = false;
          this.fetchContactDuplicates();
          this.page = 1;
        }, 2000);
      } catch (error) {
        this.notifyError();
        this.isLoading = false;

        // Don't move these to a finally, happy path is a setTimeout.
        this.isMerging = false;
        this.confirmMergeDialog = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.response-toggle {
  width: 100%;
}

.v-btn-toggle .v-btn {
  opacity: 0.9 !important;
}
</style>
