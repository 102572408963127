import axios from "axios";

import Utils from "@/api/utils";

export default {
  async bulkMergeContacts(acceptIds, rejectIds) {
    let body = {
      accept_ids: acceptIds,
      reject_ids: rejectIds,
    };

    let url = Utils.contactApiUrl(`/v1/contacts/merge/bulk`);
    let resp = await axios.post(url, body, { headers: Utils.authHeaders() });
    return resp.data;
  },

  deleteContact(contactId) {
    let url = Utils.contactApiUrl(`/v1/contacts/${contactId}`);

    return axios
      .delete(url, { headers: Utils.authHeaders() })
      .then((resp) => resp.data);
  },

  fetchContacts(opts) {
    let params = {
      limit: opts.limit || 30,
      offset: opts.offset || 0,
      q: opts.q,
      sort_by: opts.sort_by || "name",
      sort_dir: opts.sort_dir || "asc",
    };

    let url = Utils.contactApiUrl(`/v1/contacts`);

    return axios
      .get(url, { params: params, headers: Utils.authHeaders() })
      .then((resp) => resp.data);
  },

  async fetchContactDuplicates() {
    let url = Utils.contactApiUrl(`/v1/contacts/duplicates`);
    let resp = await axios.get(url, { headers: Utils.authHeaders() });
    return resp.data;
  },

  mergeContacts(contactIds) {
    let body = {
      contact_ids: contactIds,
    };

    let url = Utils.contactApiUrl(`/v1/contacts/merge`);

    return axios
      .post(url, body, { headers: Utils.authHeaders() })
      .then((resp) => resp.data);
  },

  searchContacts(opts) {
    let params = {
      limit: opts.limit || 30,
      offset: opts.offset || 0,
      filters: opts.filters,
    };

    let url = Utils.contactApiUrl(`/v1/contacts/search`);

    return axios
      .post(url, params, { headers: Utils.authHeaders() })
      .then((resp) => resp.data);
  },
};
