<template>
  <v-list subheader class="navy">
    <template v-for="group in validGroups">
      <v-subheader
        :key="group"
        class="card-title grey lighten-3 header-line mt-3"
      >
        <span class="caption">{{ titles[group] }}</span>
      </v-subheader>
      <template v-for="(item, index) in infoOfType(value, group)">
        <v-divider v-if="index > 0" :key="index" class="grey lighten-2" />
        <v-list-tile :key="item.value" class="white" dark>
          <v-list-tile-content>
            <v-list-tile-title>
              <a
                v-if="isLink(item.type)"
                :href="item.value"
                target="_blank"
                v-text="item.value"
              />
              <strong v-else class="caption" v-text="item.value" />
            </v-list-tile-title>
            <v-list-tile-sub-title>
              <strong v-text="item.label" />
            </v-list-tile-sub-title>
          </v-list-tile-content>
        </v-list-tile>
      </template>
    </template>
  </v-list>
</template>

<script>
export default {
  name: "ContactInfoList",

  props: {
    value: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },

  data() {
    return {
      titles: {
        email: "Email",
        phone_number: "Phone",
        address: "Address",
        linkedin_url: "LinkedIn",
      },
    };
  },

  computed: {
    validGroups() {
      return this.value
        .map((x) => x.type)
        .filter((value, index, self) => {
          return self.indexOf(value) === index;
        });
    },
  },

  methods: {
    countOfType(info, type) {
      return info.filter((x) => x.type === type);
    },

    icon(type) {
      let icons = {
        address: "place",
        email: "mail",
        phone_number: "phone",
        linkedin_url: "linkedin",
      };

      return icons[type] || "person";
    },

    infoOfType(info, type) {
      return info.filter((x) => x.type === type);
    },

    isLink(type) {
      return ["linkedin_url"].includes(type);
    },
  },
};
</script>

<style lang="scss">
.header-line {
  height: 40px;
}
</style>
