import axios from "axios";
import store from "../store";

import Utils from "@/api/utils";

export default {
  authorizeContacts({ provider, code, redirectUri }) {
    let url = Utils.contactImportApiUrl(`/v1/oauth/${provider}`);
    return axios
      .post(
        url,
        { type: "contacts", code: code, redirect_uri: redirectUri },
        { headers: Utils.authHeaders() },
      )
      .then((resp) => resp.data);
  },

  importGoogle(email) {
    let url = Utils.contactImportApiUrl(`/v1/import/google`);

    return axios
      .post(url, { email: email }, { headers: Utils.authHeaders() })
      .then((resp) => resp.data)
      .catch((error) => error.response.data);
  },

  importMicrosoft(email) {
    let url = Utils.contactImportApiUrl(`/v1/import/microsoft`);

    return axios
      .post(url, { email: email }, { headers: Utils.authHeaders() })
      .then((resp) => resp.data)
      .catch((error) => error.response.data);
  },

  submitDataImport(file, importType) {
    let url = Utils.contactImportApiUrl(`/v1/import/${importType}`);

    let formData = new FormData();
    formData.append("file", file);

    var headers = {};
    headers["Content-Type"] = "multipart/form-data";
    headers = Object.assign(headers, Utils.authHeaders());

    return axios
      .post(url, formData, { headers: headers })
      .then((resp) => {
        store.commit("statistics/FINISH_TOUR");
        return resp.data;
      })
      .catch((error) => error.response.data);
  },
};
