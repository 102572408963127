import Vue from "vue";
import Router from "vue-router";

import BillingView from "./views/BillingView.vue";
import ChangelogView from "./views/ChangelogView.vue";
import ConnectionsView from "./views/ConnectionsView.vue";
import ContactsView from "./views/ContactsView.vue";
import DuplicatesView from "./views/DuplicatesView.vue";
import FAQView from "./views/FAQView.vue";
import GettingStartedView from "./views/GettingStartedView.vue";
import ImportView from "./views/ImportView.vue";
import JoinTeamView from "./views/JoinTeamView.vue";
import LoginView from "./views/LoginView.vue";
import ProfileView from "./views/ProfileView.vue";
import ResetPasswordView from "./views/ResetPasswordView.vue";
import SignupView from "./views/SignupView.vue";
import TeamView from "./views/TeamView.vue";
import TeamsView from "./views/TeamsView.vue";

Vue.use(Router);

const hasToken = () => {
  return !!Vue.cookie.get("token");
};

const ifNotAuthenticated = (to, from, next) => {
  if (!hasToken()) {
    next();
    return;
  }
  next("/");
};

const ifAuthenticated = (to, from, next) => {
  if (hasToken()) {
    next();
    return;
  }

  const redirectTo = window.location.pathname;
  if (redirectTo === "/") {
    next({ name: "Signup" });
  } else {
    next({ name: "Signup", query: { from: redirectTo } });
  }
};

export default new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      redirect: "/connections",
    },
    {
      path: "/connections",
      name: "Connections",
      component: ConnectionsView,
      props: (route) => ({
        contactId: route.query.contactId || route.query.contact_id,
      }),
      beforeEnter: ifAuthenticated,
    },
    {
      path: "/import",
      name: "Import",
      component: ImportView,
      props: true,
      beforeEnter: ifAuthenticated,
    },
    {
      path: "/join-team/:token",
      name: "JoinTeam",
      component: JoinTeamView,
      props: true,
      beforeEnter: ifAuthenticated,
    },
    {
      path: "/teams",
      name: "Teams",
      component: TeamsView,
      beforeEnter: ifAuthenticated,
    },
    {
      path: "/teams/:teamId",
      name: "Team",
      component: TeamView,
      props: true,
      beforeEnter: ifAuthenticated,
    },
    {
      path: "/profile",
      name: "Profile",
      component: ProfileView,
      beforeEnter: ifAuthenticated,
    },
    {
      path: "/login",
      name: "Login",
      component: LoginView,
      beforeEnter: ifNotAuthenticated,
    },
    {
      path: "/signup",
      name: "Signup",
      component: SignupView,
      beforeEnter: ifNotAuthenticated,
    },
    {
      path: "/reset-password",
      name: "ResetPassword",
      component: ResetPasswordView,
      beforeEnter: ifNotAuthenticated,
    },
    {
      path: "/changelog",
      name: "Changelog",
      component: ChangelogView,
      beforeEnter: ifAuthenticated,
    },
    {
      path: "/faq",
      name: "FAQ",
      component: FAQView,
      beforeEnter: ifAuthenticated,
    },
    {
      path: "/getting-started",
      name: "GettingStarted",
      component: GettingStartedView,
      beforeEnter: ifAuthenticated,
    },
    {
      path: "/billing",
      name: "Billing",
      component: BillingView,
      beforeEnter: ifAuthenticated,
    },
    {
      path: "/contacts",
      name: "Contacts",
      component: ContactsView,
      beforeEnter: ifAuthenticated,
    },
    {
      path: "/contacts/duplicates",
      name: "Duplicates",
      component: DuplicatesView,
      beforeEnter: ifAuthenticated,
    },
  ],
});
