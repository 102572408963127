<template>
  <v-container grid-list-lg fluid>
    <AppHeader />
    <v-content fill-height>
      <v-layout row justify-center>
        <v-flex xs12 md8>
          <v-layout row wrap>
            <v-flex mb-3>
              <PageTitle value="Frequently Asked Questions" />
            </v-flex>
            <v-flex v-for="(topic, i) in topics" :key="i" xs12>
              <h2 class="display-1 mb-2">{{ topic.topicName }}</h2>
              <FAQPanel :contents="topic.contents" />
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-content>
  </v-container>
</template>

<script>
import AppHeader from "@/components/AppHeader";
import FAQPanel from "@/components/faq/FAQPanel";
import PageTitle from "@/components/global/PageTitle";

export default {
  name: "FAQ",
  components: {
    AppHeader,
    FAQPanel,
    PageTitle,
  },
  data() {
    return {
      topics: [
        {
          topicName: `CONNECTIONS`,
          contents: [
            {
              question: `What is a connection?`,
              answer: `A connection is someone you trust who is in your network. This is a person who you’ve met yourself or someone on your team has.`,
            },
            {
              question: `How do I connect with someone I don’t know?`,
              answer: `To connect with someone you don’t know, request an introduction from one of your teammates. Use the “Request Intro” button to create an email template that can help you form your request for your introduction.`,
            },
          ],
        },
        {
          topicName: `RELATIONSHIP SCORE`,
          contents: [
            {
              question: `What is the Relationship Score?`,
              answer: `The Relationship Score is a way for you to see how well two people know each other. Using machine learning, the Score will show you a scale from 1-100 to help you determine which person to ask for an introduction. The higher the score, the more likely it is you’ll get a better introduction.`,
            },
            {
              question: `How is the Relationship Score calculated?`,
              answer: `We use a patent pending algorithm and machine learning to calculate the strength of relationship between all connections.`,
            },
            {
              question: `Why is the Relationship Score important?`,
              answer: `The Relationship Score identifies connections with strong relationships to potential sales targets, new hires, or new investments. When the Relationship Score between two people is low, it may be more difficult for you to get an introduction. Try asking people with high relationship scores for introductions to save your time (and theirs)!`,
            },
          ],
        },
        {
          topicName: `BUILDING YOUR SCORE`,
          contents: [
            {
              question: `What does Building Your Score mean?`,
              answer: `Building Your Score is a way for you to help improve your own network. We use data from the sources of contacts that you import (like Apple, Google, and LinkedIn) to help estimate how well you know someone, so others can also ask for introductions from you. This way, you can help return the favor and add to your network!`,
            },
            {
              question: `How do I import my Apple/vCard contacts?`,
              answer: `To see how to import your iphone/icloud contacts, click the tutorial button below or watch a video.`,
              videoUrl: `https://www.youtube.com/embed/xuQokTPPHl0`,
              tourUrl: `/import?product_tour_id=73237`,
            },
            {
              question: `How do I import my Exchange contacts?`,
              answer: `To see how to import your Microsoft Exchange contacts, click the tutorial button below.`,
              tourUrl: `/import?product_tour_id=73240`,
            },
            {
              question: `How do I import my Google contacts?`,
              answer: `To see how to import your Google contacts, click the tutorial button below.`,
              tourUrl: `/import?product_tour_id=72952`,
            },
            {
              question: `How do I import my LinkedIn contacts?`,
              answer: `To see how to import your LinkedIn contacts, click the tutorial button below or watch a video.`,
              videoUrl: `https://www.youtube.com/embed/saKb26lWExg`,
              tourUrl: `/import?product_tour_id=73241`,
            },
            {
              question: `How do I import my Office 365 contacts?`,
              answer: `To see how to import your Microsoft Office 365 contacts, click the tutorial button below.`,
              tourUrl: `/import?product_tour_id=73236`,
            },
          ],
        },
        {
          topicName: `TEAMS`,
          contents: [
            {
              question: `What are teams?`,
              answer: `Teams are private, trusted circles of colleagues, friends, and connections. No one outside your Team can see who you know. Teams are an ideal place to ask for that introduction, since you already have something in common.`,
            },
            {
              question: `Can I create a new team?`,
              answer: `Yes! 6DOS works best for you when you are part of at least 5 Teams. You’d be surprised at who your friends and colleagues know! We encourage all kinds of Teams, from community service organizations to your workplace to your local sports team!`,
            },
            {
              question: `How do I create a new team?`,
              answer: `To create a new team, click on “Teams” in the top navigation bar and select the “Create Team” button. Have fun naming your team and inviting your people!`,
            },
            {
              question: `How do I invite new Team Members to a Team?`,
              answer: `To invite members to your new Team, navigate to the “Team” tab and click on the Team that you’d like to invite people to. The “Invite Members” button will generate a link and a sample email that you can use to send to those you’d like to invite. It’s important to keep in mind that anyone you invite will be able to view all the connections (but not personal contact information) of the people on your Team, so be careful who you share the link with!`,
            },
          ],
        },
        {
          topicName: `PRIVACY`,
          contents: [
            {
              question: `How secure is my data that I upload?`,
              answer: `6DOS takes data security very seriously. After all, you’re part of the reason 6DOS exists at all. We believe you own your own data, and you should be in control of who gets what information and how that information is used. 
                      While a teammate can see who you’re connected to, they CANNOT see that person’s contact information. That way you don’t have to worry that someone will be reaching out to your connections without going through you first. If a teammate would like to connect to someone in your network, they MUST request an introduction through you. You get the power to decide if you’d like to make that introduction and how that information is used.`,
            },
          ],
        },
      ],
    };
  },
};
</script>
