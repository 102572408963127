<template>
  <v-container grid-list-lg fluid>
    <AppHeader />
    <v-content fill-height>
      <v-layout row>
        <v-flex>
          <PageTitle value="Billing" />
        </v-flex>
      </v-layout>
      <v-layout>
        <v-flex>
          <v-tabs v-model="selectedTabIndex" color="transparent">
            <v-tabs-slider color="black" />
            <v-tab v-for="tabText in tabs" :key="tabText" class="mr-3">
              <span class="title font-weight-regular">{{ tabText }}</span>
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="selectedTabIndex">
            <v-tab-item v-for="(tabText, i) in tabs" :key="i" class="pt-3">
              <InvoicesTable v-if="i == 0" />
              <PreviousPaymentsTable v-if="i == 1" />
              <SubscriptionPanel
                v-if="i == 2"
                @update-subscription-page="updateSubscriptionPage"
              />
            </v-tab-item>
          </v-tabs-items>
        </v-flex>
      </v-layout>
    </v-content>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
import AppHeader from "@/components/AppHeader";
import InvoicesTable from "@/components/billing/InvoicesTable";
import PageTitle from "@/components/global/PageTitle";
import PreviousPaymentsTable from "@/components/billing/PreviousPaymentsTable";
import SubscriptionPanel from "@/components/billing/SubscriptionPanel";

export default {
  name: "BillingView",
  components: {
    AppHeader,
    InvoicesTable,
    PageTitle,
    PreviousPaymentsTable,
    SubscriptionPanel,
  },
  data() {
    return {
      selectedTabIndex: null,
      tabs: ["Invoices", "Previous Payments", "Subscription"],
    };
  },
  mounted() {
    this.fetchInvoices();
    this.fetchPaymentMethod();
    this.fetchPayments();
  },
  methods: {
    ...mapActions({
      fetchInvoices: "payment/fetchInvoices",
      fetchPaymentMethod: "payment/fetchPaymentMethod",
      fetchPaymentProfile: "payment/fetchPaymentProfile",
      fetchPayments: "payment/fetchPayments",
    }),
    updateSubscriptionPage() {
      this.fetchPaymentProfile();
      this.selectedTabIndex = 2;
    },
  },
};
</script>
