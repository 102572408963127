<template>
  <v-list two-line subheader dense class="white">
    <v-subheader class="card-title white--text orange darken-2">
      <v-icon color="white" left> emoji_events </v-icon>
      <span class="caption">Best Connector</span>
    </v-subheader>
    <v-list-tile class="py-3">
      <v-list-tile-avatar size="avatarHeight">
        <ScoreIndicator
          v-model="bestConnector.score"
          :size="avatarHeight"
          :width="scoreStroke"
          class="mr-2"
        />
      </v-list-tile-avatar>
      <v-list-tile-avatar size="avatarHeight + 5">
        <Avatar
          :size="avatarHeight"
          :username="bestConnector.name"
          class="mr-2"
        />
      </v-list-tile-avatar>
      <v-list-tile-content>
        <v-list-tile-title class="dialog-text">
          <strong>{{ bestConnector.name }}</strong>
        </v-list-tile-title>
      </v-list-tile-content>
      <v-list-tile-action v-if="$vuetify.breakpoint.smAndDown">
        <v-btn v-if="!knowsConnection" :href="mailtoLink(bestConnector)" icon>
          <v-icon color="primary">mail</v-icon>
        </v-btn>
      </v-list-tile-action>
      <v-list-tile-action v-else>
        <v-btn
          v-if="!knowsConnection"
          color="primary"
          class="px-3 elevation-0"
          :href="mailtoLink(bestConnector)"
        >
          Request Intro
        </v-btn>
      </v-list-tile-action>
    </v-list-tile>
    <template v-if="hasOtherConnectors">
      <v-subheader class="card-title">
        <span class="caption">Other Connectors</span>
      </v-subheader>

      <v-list-tile
        v-for="(item, index) in otherConnectors"
        :key="index"
        class="py-1"
      >
        <v-list-tile-avatar size="avatarHeight">
          <ScoreIndicator
            v-model="item.score"
            :size="avatarHeight"
            :width="scoreStroke"
            class="mr-2"
          />
        </v-list-tile-avatar>
        <v-list-tile-avatar size="avatarHeight + 5">
          <Avatar :size="avatarHeight" :username="item.name" class="mr-2" />
        </v-list-tile-avatar>
        <v-list-tile-content>
          <v-list-tile-title class="dialog-text">
            <strong>{{ item.name }}</strong>
          </v-list-tile-title>
        </v-list-tile-content>
        <v-list-tile-action v-if="!knowsConnection">
          <v-btn :href="mailtoLink(item)" icon>
            <v-icon color="primary">mail</v-icon>
          </v-btn>
        </v-list-tile-action>
      </v-list-tile>
    </template>
  </v-list>
</template>

<script>
import Avatar from "vue-avatar";
import { mapGetters } from "vuex";
import ScoreIndicator from "@/components/connection/ScoreIndicator";

export default {
  name: "ConnectorList",

  components: {
    Avatar,
    ScoreIndicator,
  },

  props: {
    avatarHeight: {
      type: Number,
      default: 64,
    },

    connectionName: {
      type: String,
      default: "Not set.",
    },

    knowsConnection: {
      type: Boolean,
      default: false,
    },

    value: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },

  computed: {
    ...mapGetters({
      profile: "getProfile",
    }),

    bestConnector() {
      if (this.value.length === 0) {
        return { name: "Nobody", score: 0, email: "" };
      }

      let knownBy = [...this.value];
      return knownBy.sort((a, b) => b.score - a.score)[0];
    },

    hasOtherConnectors() {
      return this.otherConnectors.length > 0;
    },

    otherConnectors() {
      let knownBy = [...this.value];
      return knownBy
        .sort((a, b) => b.score - a.score)
        .splice(1, this.value.length);
    },

    scoreStroke() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 5;
        case "sm":
          return 5;
        case "md":
          return 7;
        case "lg":
          return 7;
        case "xl":
          return 7;
        default:
          return 7;
      }
    },
  },

  methods: {
    mailtoLink(user) {
      if (!user) {
        return "";
      }

      let email = user.email;
      let subject = `6DOS Request for Intro - ${this.connectionName}`;
      let body =
        `Hey ${user.name},\n\n` +
        `I saw on 6DOS that you're connected to ${this.connectionName}, and I'd like to get in touch with them. Would you mind making an introduction or forwarding them this email?\n\n` +
        "Here is the relevant information:\n" +
        "- Opportunity: (ex: new sales opp/platform/etc)\n" +
        "- Timeline: (ex: next 2 weeks/Friday/etc)\n" +
        "- Ask: (ex: I would like an email introduction/phone call/etc)\n" +
        "- Other Relevant Information: (ex: your background/relevant article/etc)\n\n" +
        "Thank you,\n" +
        `${this.profile.name}`;

      let str = `mailto:${email}?subject=${subject}&body=${body}`;
      return encodeURI(str).replace(/,/g, "%2C");
    },
  },
};
</script>
