<template>
  <v-container grid-list-lg fill-height>
    <AppHeader />
    <v-content fluid>
      <v-layout row justify-center>
        <v-flex md12 lg6>
          <v-stepper v-model="step" class="elevation-0 transparent" vertical>
            <v-stepper-step :complete="step > 1" step="1">
              Watch the video
            </v-stepper-step>
            <v-stepper-content step="1">
              <WatchVideo @continue="videoWatched" />
            </v-stepper-content>

            <v-stepper-step :complete="step > 2" step="2">
              Upload contacts
            </v-stepper-step>
            <v-stepper-content step="2">
              <UploadContacts @continue="step = 3" @skip="skip" />
            </v-stepper-content>

            <v-stepper-step :complete="finished" step="3">
              Create a team
            </v-stepper-step>
            <v-stepper-content step="3">
              <FinishedView v-if="finished" />
              <CreateTeam
                v-if="!finished && !hasCreatedTeam"
                @create-team="teamCreated($event)"
                @skip="finish"
              />
              <InviteMembers
                v-if="!finished && hasCreatedTeam"
                :id="team.id"
                :name="team.name"
                @continue="finish"
                @skip="finish"
              />
            </v-stepper-content>
          </v-stepper>
        </v-flex>
      </v-layout>
    </v-content>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
import AppHeader from "@/components/AppHeader";
import CreateTeam from "@/components/getting-started/CreateTeam";
import FinishedView from "@/components/getting-started/FinishedView";
import InviteMembers from "@/components/getting-started/InviteMembers";
import UploadContacts from "@/components/getting-started/UploadContacts";
import WatchVideo from "@/components/getting-started/WatchVideo";

export default {
  name: "GettingStarted",
  components: {
    AppHeader,
    CreateTeam,
    FinishedView,
    InviteMembers,
    UploadContacts,
    WatchVideo,
  },
  data() {
    return {
      finished: false,
      hasCreatedTeam: false,
      team: {},
      step: 0,
    };
  },
  methods: {
    ...mapActions("statistics", ["finishTour"]),
    videoWatched() {
      this.step = 2;
    },
    teamCreated(team) {
      this.hasCreatedTeam = true;
      this.team = team;
    },
    skip() {
      this.step += 1;
    },
    finish() {
      this.finished = true;
      this.finishTour();
    },
  },
};
</script>
