<template>
  <v-container grid-list-lg fluid>
    <AppHeader />
    <v-content fill-height>
      <v-layout row wrap>
        <v-flex xs12 mb-3>
          <PageTitle value="Profile" />
        </v-flex>
        <v-flex xs12>
          <v-layout row wrap>
            <v-flex xs12 lg3>
              <v-layout row wrap>
                <v-flex xs12>
                  <UserInfoCard :profile="profile" />
                </v-flex>
                <v-flex v-if="$vuetify.breakpoint.lgAndUp && hasImportData">
                  <v-card>
                    <v-card-title class="card-title">
                      <v-icon class="mr-3">pie_chart</v-icon>
                      Sources
                    </v-card-title>
                    <v-divider />
                    <PieChart
                      :source-total-info="sourceTotalInfo"
                      class="px-4 py-2"
                    />
                  </v-card>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs12 lg9>
              <v-layout row wrap>
                <v-flex xs12>
                  <ContactSourceCard :source-total-info="sourceTotalInfo" />
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-content>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import AppHeader from "@/components/AppHeader";
import ContactSourceCard from "@/components/profile/ContactSourceCard";
import PageTitle from "@/components/global/PageTitle";
import PieChart from "@/components/profile/PieChart";
import UserInfoCard from "@/components/profile/UserInfoCard";

export default {
  name: "ProfileView",
  components: {
    AppHeader,
    ContactSourceCard,
    PageTitle,
    PieChart,
    UserInfoCard,
  },
  computed: {
    ...mapGetters({
      profile: "getProfile",
      userStats: "statistics/getUserStats",
    }),
    sourceTotalInfo() {
      let sourceTotalInfo = this.userStats.total_imported || {};
      return sourceTotalInfo;
    },
    hasImportData() {
      if (
        this.userStats.total_imported != undefined &&
        this.userStats.total_imported.all > 0
      ) {
        return true;
      }
      return false;
    },
  },
  mounted() {
    this.fetchUserStats({ userId: this.profile.id });
  },
  methods: {
    ...mapActions("statistics", ["fetchUserStats"]),
  },
};
</script>
