<template>
  <div class="multi-chip">
    <div class="content">
      <slot />
    </div>
    <v-icon v-if="close" class="close" small @click="$emit('input', false)">
      fas fa-times-circle theme--light
    </v-icon>
  </div>
</template>

<script>
export default {
  name: "MultilineChip",
  props: {
    close: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss">
.multi-chip {
  background: #e0e0e0;
  border-radius: 2px;
  padding: 8px;
  margin: 4px;
  display: flex;
  align-items: flex-start;
}

.content {
  // background: #00ff00;
  padding: 4px;
  overflow-wrap: break-word;
  whitespace: normal;
  flex-shrink: 1;
  flex-grow: 1;
}

.close {
  flex-shrink: 0;
  margin: 2px;

  &:hover {
    color: #111;
  }
}
</style>
