<template>
  <v-layout row wrap>
    <v-flex xs12>
      <v-card v-if="isLoading" class="transparent" flat>
        <v-progress-circular
          :size="80"
          class="ml-3 mt-3"
          color="darkNavy"
          indeterminate
        />
      </v-card>
      <v-card v-else class="transparent" flat>
        <v-card-title class="pb-0">
          <span>{{ subscriptionText }}</span>
        </v-card-title>
        <v-card-actions>
          <v-btn
            v-if="isActiveStatus"
            color="ml-2 mt-3"
            dark
            large
            @click="showCancelDialog"
          >
            Cancel Subscription
          </v-btn>
          <v-btn
            v-if="isCancelledStatus"
            color="primary ml-2 mt-3"
            large
            @click="doReActivate"
          >
            Re-activate
          </v-btn>
          <v-btn
            v-if="isNoSubscriptionStatus"
            color="primary ml-2 mt-3"
            large
            @click="doSubscribe"
          >
            Subscribe
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-flex>
    <v-flex shrink>
      <PaymentMethod />
    </v-flex>
    <v-dialog v-model="showDialog" width="375">
      <v-card>
        <v-card-title class="card-title"> Cancel Subscription</v-card-title>
        <v-card-text>
          Are you sure you want to cancel subscription on 6DOS?
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" block @click="doCancelSubscription">
            Yes
          </v-btn>
          <v-btn dark block @click="hideCancelDialog"> No </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import { mapGetters } from "vuex";
import PaymentApi from "@/api/payment";
import PaymentMethod from "@/components/billing/PaymentMethod";
import whitelabelConfig from "@/whitelabel.config";

export default {
  name: "SubscriptionPanel",
  components: {
    PaymentMethod,
  },
  data() {
    return {
      showDialog: false,
    };
  },
  computed: {
    ...mapGetters({
      isLoading: "payment/isLoading",
      paymentProfile: "payment/getPaymentProfile",
    }),
    isActiveStatus() {
      // status should be: active / cancelled / no_subscription
      if (this.paymentProfile) {
        let status = this.paymentProfile.subscription_status;
        return status === "active";
      }
      return false;
    },
    isCancelledStatus() {
      if (this.paymentProfile) {
        let status = this.paymentProfile.subscription_status;
        return status === "cancelled";
      }
      return false;
    },
    isNoSubscriptionStatus() {
      if (this.paymentProfile) {
        let status = this.paymentProfile.subscription_status;
        return status === "no_subscription";
      }
      return false;
    },
    subscriptionText() {
      if (this.paymentProfile) {
        let text = this.paymentProfile.subscription_detail;
        return text;
      }
      return "";
    },
  },
  methods: {
    doReActivate() {
      PaymentApi.doReactivateSubscription()
        .then((_resp) => {
          this.$emit("update-subscription-page");
        })
        .catch((_error) => {});
    },
    doSubscribe() {
      PaymentApi.fetchCheckoutSessionId()
        .then((resp) => {
          this.redirectToStripePage(resp.data);
        })
        .catch((_error) => {});
    },
    doCancelSubscription() {
      this.hideCancelDialog();
      PaymentApi.doCancelSubscription()
        .then((_resp) => {
          this.$emit("update-subscription-page");
        })
        .catch((_error) => {});
    },
    getWhiteLabelConfig() {
      return whitelabelConfig[process.env.VUE_APP_BRAND];
    },
    hideCancelDialog() {
      this.showDialog = false;
    },
    redirectToStripePage(sessionId) {
      // Redirect to Stripe page showing 6DOS plan with a session id
      let stripe = window.Stripe(
        this.getWhiteLabelConfig().stripePublishableKey,
      );
      stripe
        .redirectToCheckout({ sessionId: sessionId })
        .then(function (result) {
          if (result.error) {
            this.$notify({
              group: "notifs",
              title: "Payment Request Failed",
              text: result.error.message,
              type: "error",
            });
          }
        });
    },
    showCancelDialog() {
      this.showDialog = true;
    },
  },
};
</script>

<style scoped>
.transparent {
  background-color: transparent !important;
}
</style>
