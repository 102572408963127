import axios from "axios";
import store from "../store";
import router from "../router";

import Utils from "@/api/utils";

axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response.status === 401) {
      store.dispatch("logout");
      router.push({ name: "Login" });
    }

    return Promise.reject(error);
  },
);

export default {
  auth({ provider, code, type, redirectUri }) {
    if (type === "register") {
      store.commit("statistics/NEEDS_TOUR");
    }

    let url = Utils.accountApiUrl(`/v1/oauth/${provider}`);
    return axios
      .post(url, { type: type, code: code, redirect_uri: redirectUri })
      .then((resp) => resp.data);
  },

  changePassword(token, password) {
    let url = Utils.accountApiUrl("/v1/change_password");
    return axios
      .post(
        url,
        { t: token, password: password },
        { headers: Utils.authHeaders() },
      )
      .then((resp) => resp.data);
  },

  fetchAccountUsers(accountId) {
    let url = Utils.accountApiUrl(`/v1/accounts/${accountId}/users`);
    return axios
      .get(url, { headers: Utils.authHeaders() })
      .then((resp) => resp.data);
  },

  fetchMotd() {
    let url = Utils.accountApiUrl("/v1/motd");
    return axios
      .get(url, { headers: Utils.authHeaders() })
      .then((resp) => resp.data);
  },

  fetchProfile() {
    let url = Utils.accountApiUrl("/v1/profile");
    return axios
      .get(url, { headers: Utils.authHeaders() })
      .then((resp) => resp.data);
  },

  login(email, pass) {
    let url = Utils.accountApiUrl("/v1/oauth/email");
    return axios
      .post(url, { type: "login", email: email, password: pass })
      .then((resp) => resp.data);
  },

  register(name, email, pass) {
    store.commit("statistics/NEEDS_TOUR");

    let url = Utils.accountApiUrl("/v1/oauth/email");
    return axios
      .post(url, { type: "register", name: name, email: email, password: pass })
      .then((resp) => resp.data);
  },

  requestPasswordReset(email) {
    let url = Utils.accountApiUrl("/v1/forgot_password");
    return axios.post(url, { email: email }).then((resp) => resp.data);
  },

  userChangePassword(oldPassword, newPassword) {
    let url = Utils.accountApiUrl("/v1/profile/password");
    return axios
      .put(
        url,
        { old_password: oldPassword, new_password: newPassword },
        { headers: Utils.authHeaders() },
      )
      .then((resp) => resp.data);
  },
};
