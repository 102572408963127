// IE11 Promise polyfill
import "babel-polyfill";
import Es6Promise from "es6-promise";

Es6Promise.polyfill();

import Vue from "vue";
import "./plugins/vuetify";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import VueClipboard from "vue-clipboard2";

VueClipboard.config.autoSetContainer = true;
Vue.use(VueClipboard);

import VueCookie from "vue-cookie";

Vue.use(VueCookie);

import whiteLabelConfig from "@/whitelabel.config";

let config = whiteLabelConfig[process.env.VUE_APP_BRAND];

import VueGtag from "vue-gtag";
import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";
import { version } from "../package.json";

import Notifications from "vue-notification";
import i18n from "./i18n";

Vue.use(Notifications);

import checkView from "vue-check-view";

Vue.use(checkView);

import WhiteLabelPlugin from "./whitelabel-plugin";

Vue.use(WhiteLabelPlugin);

Vue.config.productionTip = false;

if (process.env.VUE_APP_BRAND == "prod" || process.env.VUE_APP_BRAND == "dev") {
  Vue.use(
    VueGtag,
    {
      config: { id: config.ganalyticsId },
    },
    router,
  );

  var sentryEnv = "none";
  switch (process.env.VUE_APP_BRAND) {
    case "prod":
      sentryEnv = "production";
      break;
    case "dev":
      sentryEnv = "development";
      break;
    default:
      break;
  }

  Sentry.init({
    Vue,
    dsn: "https://a48bbab97b4a406984d8bb9fd9a06f87@o235257.ingest.sentry.io/6457329",
    environment: sentryEnv,
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: ["localhost", "app.6dos.co", "app.6dos.dev", /^\//],
      }),
    ],
    release: `6dos-web@${version}`,
    tracesSampleRate: 1.0,
  });
}

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
