<template>
  <v-card class="elevation-0" tile>
    <slot></slot>
    <v-card-text>
      <v-layout row wrap>
        <v-flex xs12>
          <!-- eslint-disable vue/no-v-html -->
          <div class="mb-3" v-html="bodyText" />
          <!-- eslint-enable vue/no-v-html -->
          <v-btn
            v-if="hasVideo"
            color="warning"
            class="elevation-0 mb-3"
            large
            block
            @click="showVideo = true"
          >
            <v-icon left>videocam</v-icon>
            Watch Video
          </v-btn>
          <v-btn
            :color="color"
            class="pl-1 pr-3 elevation-0"
            large
            dark
            block
            @click="requestImport"
          >
            <img v-if="useImage" :src="image" />
            <v-icon v-else color="white" class="ml-2" v-text="icon" />
            <v-progress-linear
              v-if="isLoading"
              color="white"
              class="mx-3"
              :indeterminate="true"
            />
            <span v-else class="button-label"> Import Contacts </span>
            <input
              ref="file"
              type="file"
              style="display: none"
              @change="handleFileUpload"
            />
          </v-btn>
        </v-flex>
      </v-layout>
    </v-card-text>
    <v-dialog v-model="showVideo" class="pa-0" width="1120">
      <v-card class="ma-0 pa-0">
        <div class="video-container">
          <iframe
            width="1120"
            height="630"
            :src="videoUrl"
            frameborder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          />
        </div>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
import API from "@/api/import";
import OAuth from "@/oauth";

const titles = {
  google: "Google",
  microsoft: "Office 365",
  linkedin: "LinkedIn",
  vcard: "Apple / VCard",
  exchange: "Exchange",
};

const colors = {
  google: "#4285F4",
  microsoft: "#D53A00",
  linkedin: "#0077B5",
  vcard: "#7d7d7d",
  exchange: "#00a1f1",
};

const videoUrl = {
  linkedin: "https://www.youtube.com/embed/saKb26lWExg",
  vcard: "https://www.youtube.com/embed/xuQokTPPHl0",
};

const icons = {
  vcard: "contact_mail",
};

export default {
  name: "ImportCard",
  props: {
    type: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      isLoading: false,
      showVideo: false,
    };
  },
  computed: {
    title() {
      return titles[this.type];
    },
    color() {
      return colors[this.type];
    },
    videoUrl() {
      if (!this.showVideo) {
        return null;
      }
      return videoUrl[this.type];
    },
    hasVideo() {
      return videoUrl[this.type] != null;
    },
    image() {
      switch (this.type) {
        case "google":
          return require("@/assets/google-logo.png");
        case "microsoft":
          return require("@/assets/office-logo.png");
        case "linkedin":
          return require("@/assets/linkedin-logo.png");
        default:
          return null;
      }
    },
    icon() {
      return icons[this.type];
    },
    useImage() {
      return this.image != null;
    },
    redirectUri() {
      return `${this.$whiteLabelConfig.webUrl}/import`;
    },
    bodyText() {
      switch (this.type) {
        case "google":
          return `
        <p>
          <strong>Time Required:</strong>  < 1 Minute
        </p>
        <p>
          <strong>You’ll Need:</strong>
          <br />
          Your Google email and password
        </p>
        <p>Importing contacts from Google only takes a few seconds and will help your team build a better network.</p>
        `;

        case "microsoft":
          return `
        <p>
        <strong>Time Required:</strong>  < 1 Minute
      </p>
      <p>
        <strong>You’ll Need:</strong>
        <br />
        Your O365 email and password
      </p>
      <p>Importing contacts from Office 365 only takes a few seconds and will help your team build a better network.</p>
      `;

        case "linkedin":
          return `
      <p><strong>Time Required:</strong>  3 Minutes</p>
      <p><strong>LinkedIn Delay:</strong> 10 Minutes</p>
      <p><strong>You’ll Need:</strong><br />  Your LinkedIn email and password</p>

      <p>We know, it’s not ideal. But LinkedIn doesn’t provide an API to move your contacts. You’ll need to manually export your contacts from LinkedIn and upload them to 6DOS.</p>

      <ol>
        <li><a href="https://www.linkedin.com/psettings/member-data" target="_blank">Click this link</a> to open your data settings for LinkedIn</li>
        <li>Under <strong>"Getting a copy of your data"</strong>, select <strong>"Want something in particular? Select the data files you're most interested in."</strong></li>
        <li>Check <strong>"Connections"</strong></li>
        <li>Click <strong>"Request Archive"</strong></li>
        <li>In 10-20 minutes, LinkedIn will email you a zip file</li>
        <li>Unzip the file</li>
        <li>Here on 6DOS, click <strong>"IMPORT CONTACTS"</strong></li>
        <li>Select the file that was exported from LinkedIn</li>
      </ol>
      `;

        case "vcard":
          return `
      <p><strong>Time Required:</strong>  3 Minutes</p>
      <p><strong>You’ll Need:</strong><br />  Your iCloud email, password, and mobile device</p>

      <p>We know, it’s not ideal. But until we finish our mobile app you’ll need to manually export your contacts from iCloud and upload them to 6DOS.</p>

      <ol>
        <li><a href="https://www.icloud.com/#contacts" target="_blank">Click this link</a> to log into iCloud on this device</li>
        <li>On iCloud, in the bottom left corner, click the cog/sprocket icon</li>
        <li>In the menu that appears, click <strong>“Select All”</strong></li>
        <li>Again, in the bottom left corner, click the cog/sprocket icon</li>
        <li>In the menu that appears, click <strong>“Export vCard…”</strong></li>
        <li>When prompted, save the file to a place that’s easy to access</li>
        <li>On 6DOS, click <strong>"IMPORT CONTACTS"</strong></li>
        <li>Select the file that was exported from iCloud</li>
      </ol>
      `;

        case "exchange":
          return `
      <p>
        <strong>Time Required:</strong>  3 Minutes
      </p>
      <p><strong>You’ll Need:</strong><br />  Your Outlook email and password</p>

      <ol>
        <li>Open Outlook and click <strong>"File"</strong> in the top left</li>
        <li>Select <strong>"Open & Export"</strong> from the left menu</li>
        <li>Click <strong>"Import/Export"</strong> from the list of Open options</li>
        <li>Select <strong>"Export to a file"</strong> and then click <strong>"Next"</strong></li>
        <li>Select <strong>"Comma Separated Values"</strong> and then click <strong>"Next"</strong></li>
        <li>Select <strong>"Contacts"</strong> as the data file type that you want to export then click <strong>"Next"</strong></li>
        <li>Click <strong>"Browse"</strong> then name and save the file to a place that you can locate for the next step</li>
        <li>Click <strong>"Next"</strong> and then select <strong>"Finish"</strong> to export and save the file to your local computer</li>
        <li>On 6DOS, click the import contacts button</li>
        <li>Select the file that was exported from Outlook</li>
      </ol>
      `;

        default:
          return `
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore 
        et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut 
        aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum 
        dolore eu fugiat nulla pariatur.
      </p>
      `;
      }
    },
  },
  methods: {
    ...mapActions(["oauth"]),
    clearFileInput() {
      this.file = null;
      const input = this.$refs.file;
      input.type = "text";
      input.type = "file";
    },
    handleFileUpload() {
      this.file = this.$refs.file.files[0];
      if (this.file == null) {
        return;
      }

      let importTypes = {
        vcard: "icloud",
        linkedin: "linkedin",
        exchange: "exchange",
      };

      this.isLoading = true;

      API.submitDataImport(this.file, importTypes[this.type])
        .then((resp) => {
          if (!resp.success) {
            this.notifyError(resp.error.message);
          } else {
            this.notifySuccess();
          }
        })
        .catch((_error) => {
          this.notifyError();
        })
        .finally(() => {
          this.clearFileInput();
          this.isLoading = false;
        });
    },
    requestImport() {
      switch (this.type) {
        case "google":
          this.requestGooglePermissions();
          return;

        case "microsoft":
          this.requestMicrosoftPermissions();
          return;

        case "linkedin":
          this.importFile();
          return;

        case "vcard":
          this.importFile();
          return;

        case "exchange":
          this.importFile();
          return;

        default:
          return;
      }
    },
    importFile() {
      this.$refs.file.click();
    },
    importGoogle(email) {
      this.isLoading = true;

      API.importGoogle(email)
        .then((resp) => {
          if (resp.success) {
            this.notifySuccess();
            return;
          }
          this.notifyError(resp.error.message);
        })
        .catch((error) => {
          this.notifyError(error.message);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    importMicrosoft(email) {
      this.isLoading = true;

      API.importMicrosoft(email)
        .then((resp) => {
          if (resp.success) {
            this.notifySuccess();
            return;
          }
          this.notifyError(resp.error.message);
        })
        .catch((error) => {
          this.notifyError(error.message);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    notifyError(msg = "If this issue persists, please contact support.") {
      this.$notify({
        group: "notifs",
        title: "Request Failed",
        text: msg,
        type: "error",
      });
    },
    notifySuccess() {
      this.$emit("success");
      this.$notify({
        group: "notifs",
        title: "Success",
        text: "Contact import started. Check back later for finished results.",
        type: "success",
      });
    },
    requestGooglePermissions() {
      let scope = ["https://www.googleapis.com/auth/contacts.readonly"];
      let opts = { optionalScopes: scope, prompt: "consent" };
      let popup = OAuth.googlePopup("Contacts", this.redirectUri, opts);

      popup
        .open(this.redirectUri, false)
        .then((resp) => {
          API.authorizeContacts({
            provider: "google",
            code: resp.code,
            redirectUri: this.redirectUri,
          })
            .then((resp) => {
              this.importGoogle(resp.data.email);
            })
            .catch((_error) => {
              this.notifyError();
            });
        })
        .catch((error) => {
          if (error.message === "CLOSED") {
            return;
          }
          this.notifyError();
        });
    },
    requestMicrosoftPermissions() {
      let opts = {
        optionalScopes: ["contacts.read"],
        prompt: "select_account",
      };
      let popup = OAuth.microsoftPopup("Contacts", this.redirectUri, opts);

      popup
        .open(this.redirectUri, false)
        .then((resp) => {
          API.authorizeContacts({
            provider: "microsoft",
            code: resp.code,
            redirectUri: this.redirectUri,
          })
            .then((resp) => {
              this.importMicrosoft(resp.data.email);
            })
            .catch((error) => {
              if (error.message === "CLOSED") {
                return;
              }
              this.notifyError();
            });
        })
        .catch((error) => {
          if (error.message === "CLOSED") {
            return;
          }
          this.notifyError();
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.button-logo {
  position: absolute;
  left: 8px;
}

.button-label {
  width: 100%;
}

div.btn__content {
  background-color: #f00 !important;
  margin: 16px;
}

.video-container {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  height: 0;
  overflow: hidden;
}

.video-container iframe,
.video-container object,
.video-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
</style>
