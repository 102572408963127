import StatsApi from "@/api/statistics";
import Vue from "vue";

export const FETCH_USER_STATS_REQUEST = "FETCH_USER_STATS_REQUEST";
export const FETCH_USER_STATS_SUCCESS = "FETCH_USER_STATS_SUCCESS";
export const FETCH_USER_STATS_FAILURE = "FETCH_USER_STATS_FAILURE";

export const FETCH_TEAM_STATS_REQUEST = "FETCH_TEAM_STATS_REQUEST";
export const FETCH_TEAM_STATS_SUCCESS = "FETCH_TEAM_STATS_SUCCESS";
export const FETCH_TEAM_STATS_FAILURE = "FETCH_TEAM_STATS_FAILURE";

export const NEEDS_TOUR = "NEEDS_TOUR";
export const BEGIN_TOUR = "BEGIN_TOUR";
export const FINISH_TOUR = "FINISH_TOUR";

export const RESET_STATE = "RESET_STATE";

const state = {
  loading: false,
  error: null,
  userStats: {},
  teamStats: {},
  needsTourDialog: Boolean(localStorage.getItem("needsTour")),
};

const getters = {
  isLoading: (state) => {
    return state.loading;
  },

  getUserStats: (state) => {
    return state.userStats;
  },

  getTeamStats: (state) => {
    return state.teamStats;
  },

  needsTourDialog: (state) => {
    return state.needsTourDialog;
  },
};

const actions = {
  fetchUserStats({ commit }, { userId }) {
    return new Promise((resolve, reject) => {
      commit(FETCH_USER_STATS_REQUEST);
      StatsApi.fetchUserStats(userId)
        .then((resp) => {
          commit(FETCH_USER_STATS_SUCCESS, {
            userStatsRawData: resp.data,
            userId: userId,
          });
          resolve(resp.data);
        })
        .catch((error) => {
          commit(FETCH_USER_STATS_FAILURE, { msg: error });
          reject(error);
        });
    });
  },

  fetchTeamStats({ commit }, { teamId }) {
    return new Promise((resolve, reject) => {
      commit(FETCH_TEAM_STATS_REQUEST);
      StatsApi.fetchTeamStats(teamId)
        .then((resp) => {
          commit(FETCH_TEAM_STATS_SUCCESS, {
            teamStatsRawData: resp.data,
            teamId: teamId,
          });
          resolve(resp.data);
        })
        .catch((error) => {
          commit(FETCH_TEAM_STATS_FAILURE, { msg: error });
          reject(error);
        });
    });
  },

  beginTour({ commit }) {
    commit(BEGIN_TOUR);
  },

  finishTour({ commit }) {
    commit(FINISH_TOUR);
  },
};

const mutations = {
  [FETCH_USER_STATS_REQUEST](state) {
    state.loading = true;
    state.error = null;
  },

  [FETCH_USER_STATS_SUCCESS](state, { userStatsRawData, _userId }) {
    state.loading = false;
    state.error = null;

    Vue.set(state, "userStats", userStatsRawData);
  },

  [FETCH_USER_STATS_FAILURE](state, { error }) {
    state.loading = false;
    state.error = error;
  },

  [FETCH_TEAM_STATS_REQUEST](state) {
    state.loading = true;
    state.error = null;
  },

  [FETCH_TEAM_STATS_SUCCESS](state, { teamStatsRawData, _teamId }) {
    state.loading = false;
    state.error = null;

    Vue.set(state, "teamStats", teamStatsRawData);
  },

  [FETCH_TEAM_STATS_FAILURE](state, { error }) {
    state.loading = false;
    state.error = error;
  },

  [NEEDS_TOUR](state) {
    localStorage.setItem("needsTour", "true");
    state.needsTourDialog = true;
  },

  [BEGIN_TOUR](_state) {
    // Nothing
  },

  [FINISH_TOUR](state) {
    localStorage.setItem("needsTour", "false");
    state.needsTourDialog = false;
  },

  [RESET_STATE](state) {
    localStorage.removeItem("needsTour");
    const initialState = {
      loading: false,
      error: null,
      userStats: {},
      teamStats: {},
      needsTourDialog: false,
    };
    Object.assign(state, initialState);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
