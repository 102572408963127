<template>
  <v-container grid-list-lg fill-height>
    <AppHeader />
    <v-content fill-height>
      <v-layout row justify-center align-center fill-height>
        <v-card class="pa-3">
          <template v-if="validToken">
            <v-card-text>
              <h1 class="display-2 text-xs-center pb-3">
                Would you like to join {{ teamName }}?
              </h1>
              <p class="subtitle-1 text-xs-center">
                By joining, you and other team members will be able to view each
                other's 6DOS connections to better facilitate introductions.
                <br />Team members will only see the names, titles, and
                companies of your connections and will not be able to contact
                your connections directly.
              </p>
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn
                color="primary"
                class="elevation-0"
                large
                dark
                @click="acceptInvite()"
              >
                <span class="button-label">Join Team</span>
              </v-btn>
              <v-btn
                color="grey darken-2"
                class="elevation-0"
                large
                outline
                @click="declineInvite()"
              >
                <span class="button-label">Reject Invite</span>
              </v-btn>
              <v-spacer />
            </v-card-actions>
          </template>
          <v-card-text v-if="!validToken">
            <h1>
              The invite link is invalid or has expired. Please request another
              link and try again.
            </h1>
          </v-card-text>
        </v-card>
      </v-layout>
    </v-content>
  </v-container>
</template>

<script>
import API from "@/api/teams";
import AppHeader from "@/components/AppHeader";

export default {
  name: "JoinTeam",
  components: {
    AppHeader,
  },
  props: {
    token: {
      type: String,
      default: null,
      required: false,
    },
  },
  data: function () {
    return {
      teamName: "",
      validToken: true,
    };
  },
  mounted() {
    this.getTokenData();
  },
  methods: {
    acceptInvite() {
      API.joinTeam(this.token)
        .then(() => this.$router.push("/connections"))
        .catch((error) =>
          this.$notify({
            group: "notifs",
            title: "Request Failed",
            text: error.data.message,
            type: "error",
          }),
        );
    },
    declineInvite() {
      this.$router.push("/connections");
    },
    getTokenData() {
      API.fetchTokenData(this.token)
        .then((resp) => (this.teamName = resp.data.name))
        .catch(() => (this.validToken = false));
    },
  },
};
</script>
