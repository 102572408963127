<template>
  <v-layout row wrap>
    <v-flex xs12 pb-0>
      <v-select
        v-model="activeFilter"
        label="Select New Filter"
        :items="names"
        item-text="label"
        item-value="key"
        return-object
      />
    </v-flex>
    <v-flex v-if="nameSelected" xs12 py-0>
      <v-autocomplete
        v-model="activeWhere"
        :items="whereOptions"
        item-text="label"
        item-value="key"
      />
    </v-flex>
    <v-flex v-if="needsString" xs12 py-0>
      <v-text-field
        v-model="value"
        :counter="150"
        maxlength="150"
        @keyup.enter.native="addFilter"
      />
    </v-flex>
    <v-flex v-if="needsNumber" xs12 py-0>
      <v-text-field
        v-model="value"
        type="number"
        step="1"
        @keyup.enter.native="addFilter"
      />
    </v-flex>
    <v-flex v-if="needsUserList" xs12 py-0>
      <v-autocomplete
        v-model="value"
        :search-input.sync="searchValue"
        :items="searchedUsers"
        item-text="name"
        item-value="id"
        label="Search by name"
        prepend-inner-icon="search"
        return-object
        @keyup.enter.native="addFilter"
      >
        <template #item="users">
          <v-list-tile-avatar>
            <Avatar :username="users.item.name" :size="40" />
          </v-list-tile-avatar>
          <v-list-tile-content>
            <v-list-tile-title>{{ users.item.name }}</v-list-tile-title>
            <v-list-tile-sub-title>
              {{ users.item.email }}
            </v-list-tile-sub-title>
          </v-list-tile-content>
        </template>
      </v-autocomplete>
    </v-flex>
    <v-flex v-if="needsCurrentOption" py-0 xs12>
      <v-select
        v-model="currentOption"
        label="Show results for"
        :items="currentOptions"
        item-text="label"
        item-value="value"
      />
    </v-flex>
    <v-flex py-0 xs12>
      <v-btn
        color="primary white--text"
        class="elevation-0"
        block
        @click="addFilter"
      >
        <v-icon class="white--text" left> add </v-icon>
        Add Filter
      </v-btn>
    </v-flex>
    <v-flex py-0 xs12>
      <v-btn
        color="primary white--text"
        class="my-0"
        block
        flat
        @click="toggleSearchMode"
      >
        Basic Search
      </v-btn>
    </v-flex>
  </v-layout>
</template>

<script>
import * as Attrs from "./available-filters";
import * as Filters from "./index";
import Avatar from "vue-avatar";
import TeamsApi from "@/api/teams";

export default {
  name: "AddFilter",
  components: {
    Avatar,
  },
  data() {
    return {
      activeFilter: null,
      activeWhere: null,
      currentOption: true,
      currentOptions: [
        { label: "Only current information", value: true },
        { label: "Current and past information", value: null },
      ],
      value: "",
      names: Attrs.filters,
      searchValue: "",
      searchedUsers: [],
    };
  },
  computed: {
    nameSelected() {
      return this.activeFilter != null;
    },
    whereOptions() {
      if (this.activeFilter == null) {
        return null;
      }
      const opts = Filters.typeOptions[this.activeFilter.type];
      return opts.map((val) => {
        return { key: val, label: Filters.whereLabels[val] };
      });
    },
    needsCurrentOption() {
      if (this.activeWhere == null) {
        return false;
      }
      return ["CONTAINS", "MATCHES_EXACTLY", "NOT_CONTAINS"].includes(
        this.activeWhere,
      );
    },
    needsString() {
      if (this.activeWhere == null) {
        return false;
      }
      return Filters.inputValueType[this.activeWhere] === "string";
    },
    needsNumber() {
      if (this.activeWhere == null) {
        return false;
      }
      return Filters.inputValueType[this.activeWhere] === "number";
    },
    needsUserList() {
      if (this.activeWhere == null) {
        return false;
      }
      return Filters.inputValueType[this.activeWhere] === "user";
    },
    canAdd() {
      if (this.activeWhere == null) {
        return false;
      }
      if (this.value == null || this.value == "") {
        return false;
      }
      return true;
    },
  },
  watch: {
    activeFilter(_val) {
      this.activeWhere = Filters.typeOptions[this.activeFilter.type][0];
    },
    activeWhere(_val) {
      this.value = null;
    },
    searchValue: {
      handler(_oldValue, newValue) {
        if (newValue == null) {
          return;
        }
        this.searchUserRequest();
      },
    },
  },
  mounted() {
    this.loadDefaultFilter();
  },
  methods: {
    addFilter() {
      if (!this.canAdd) {
        return;
      }

      let filter = {
        name: this.activeFilter.key,
        nameLabel: this.activeFilter.label,
        where: this.activeWhere,
        whereLabel: Filters.whereLabels[this.activeWhere],
        current: this.currentOption,
      };

      let filterKey = this.activeFilter.key;
      if (filterKey === "user_id" || filterKey === "team_id") {
        filter.value = this.value.id;
        filter.valueLabel = this.value.name;
      } else {
        filter.value = this.value.trim();
        filter.valueLabel = this.value.trim();
      }

      this.$emit("add-filter", filter);
      this.reset();
    },
    loadDefaultFilter() {
      this.activeFilter = Attrs.filters[0];
      this.value = "";
    },
    reset() {
      this.loadDefaultFilter();
    },
    searchUserRequest() {
      let params = { limit: 10, offset: 0, role: null };

      if (this.searchValue && this.searchValue !== "") {
        params.q = this.searchValue;
      }

      TeamsApi.fetchAllUsers(params)
        .then((resp) => {
          this.searchedUsers = resp.data;
        })
        .catch((_error) => {});
    },
    toggleSearchMode() {
      this.$emit("toggle-search-mode");
    },
  },
};
</script>
