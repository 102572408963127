<template>
  <v-layout my-0 row>
    <v-flex py-0 xs8 lg9>
      <v-text-field
        v-model="searchText"
        class="mt-1"
        prepend-inner-icon="search"
        placeholder="Search..."
        solo
        @keyup.enter.native="$emit('search', searchText)"
      />
    </v-flex>
    <v-flex py-0 xs4 lg3>
      <v-btn color="primary" large block @click="$emit('search', searchText)">
        Search
      </v-btn>
    </v-flex>
  </v-layout>
</template>

<script>
export default {
  name: "SearchBar",
  props: {
    value: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      searchText: "",
    };
  },
  computed: {
    search: {
      get() {
        return this.value;
      },
      set(search) {
        this.$emit("input", search);
      },
    },
  },
};
</script>
