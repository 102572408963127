<template>
  <div class="upload-contacts">
    <h1 class="text-xs-center">Upload your contacts.</h1>
    <p class="text-xs-center">It's easy. Select the services you use most.</p>
    <v-list class="elevation-3 mb-3 mx-1" width="100%" two-line>
      <template v-for="(source, index) in sources">
        <v-divider v-if="index != 0" :key="index" />
        <v-list-tile :key="source" @click="openSource(source)">
          <v-list-tile-avatar class="mr-2">
            <v-avatar :class="statusColor(source)">
              <v-icon color="white">check</v-icon>
            </v-avatar>
          </v-list-tile-avatar>
          <v-list-tile-avatar class="mr-2" size="40" tile>
            <img :src="imgFor(source)" />
          </v-list-tile-avatar>
          <v-list-tile-content v-if="$vuetify.breakpoint.mdAndUp">
            <v-list-tile-title class="card-title" v-text="nameFor(source)" />
          </v-list-tile-content>
          <v-list-tile-action>
            <v-icon>chevron_right</v-icon>
          </v-list-tile-action>
        </v-list-tile>
      </template>
    </v-list>
    <v-card color="transparent" class="elevation-0">
      <v-card-actions>
        <v-spacer />
        <v-btn :disabled="!canContinue" color="primary" @click="next">
          Continue
        </v-btn>
        <v-btn color="primary" flat @click="skip"> Skip For Now </v-btn>
        <v-spacer />
      </v-card-actions>
    </v-card>
    <v-dialog v-model="open" width="800" @keydown.esc="close">
      <ImportCard
        :type="activeSource"
        @success="importSuccessful(activeSource)"
      >
        <v-card-title>
          <img :src="imgFor(activeSource)" width="40" />
          <span class="white card-title pa-3" v-text="nameFor(activeSource)" />
        </v-card-title>
      </ImportCard>
    </v-dialog>
  </div>
</template>

<script>
import ImportCard from "@/components/import/ImportCard";

let sourceData = {
  vcard: {
    name: "Apple / VCard",
    img: "@/assets/social/apple.png",
  },
  exchange: {
    name: "Outlook",
    img: "@/assets/social/outlook-logo.png",
  },
  google: {
    name: "Google",
    img: "@/assets/social/google.png",
  },
  linkedin: {
    name: "LinkedIn",
    img: "@/assets/social/linkedin.png",
  },
  microsoft: {
    name: "Office 365",
    img: "@/assets/social/office365.png",
  },
};

export default {
  name: "UploadContacts",
  components: {
    ImportCard,
  },
  data() {
    return {
      activeSource: null,
      open: false,
      sources: ["vcard", "exchange", "google", "linkedin", "microsoft"],
      uploadedSources: [],
    };
  },
  computed: {
    canContinue() {
      return this.uploadedSources.length > 0;
    },
  },
  methods: {
    next() {
      this.$emit("continue");
    },
    skip() {
      this.$emit("skip");
    },
    nameFor(source) {
      return (sourceData[source] || {})["name"];
    },
    importSuccessful(source) {
      this.uploadedSources.push(source);
      this.open = false;
    },
    openSource(source) {
      this.activeSource = source;
      this.open = true;
    },
    statusColor(source) {
      return this.uploadedSources.includes(source)
        ? "success"
        : "grey lighten-2";
    },
    imgFor(source) {
      // require doesn't support variables from an object *shrug*

      switch (source) {
        case "vcard":
          return require("@/assets/social/apple.png");
        case "exchange":
          return require("@/assets/outlook-logo.png");

        case "google":
          return require("@/assets/social/google.png");

        case "linkedin":
          return require("@/assets/social/linkedin.png");

        case "microsoft":
          return require("@/assets/social/office365.png");

        default:
          return "";
      }
    },
  },
};
</script>

<style>
.video-container {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  height: 0;
  overflow: hidden;
}

.video-container iframe,
.video-container object,
.video-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
</style>
