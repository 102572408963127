<template>
  <v-card height="100%" class="elevation-0 my-5" color="transparent">
    <h1 class="text-xs-center">Create a team.</h1>
    <p class="text-xs-center">
      Name your team and start inviting people to your network. Let the fun
      begin!
    </p>
    <v-layout row wrap>
      <v-flex xs12 md9>
        <v-text-field
          v-model="name"
          solo
          @keyup.enter.native="requestCreateTeam(name)"
        />
      </v-flex>
      <v-flex xs12 md3>
        <v-btn color="primary" block @click="requestCreateTeam(name)">
          Create
        </v-btn>
      </v-flex>
    </v-layout>
    <v-card-actions>
      <v-spacer />
      <v-btn color="primary" flat @click="skip"> Skip For Now </v-btn>
      <v-spacer />
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "CreateTeam",
  data() {
    return {
      name: "",
    };
  },
  methods: {
    ...mapActions("teams", ["createTeam"]),
    next() {
      this.$emit("continue");
    },
    requestCreateTeam(name) {
      if (name == null || name == "") {
        return;
      }

      this.createTeam({ name: name }).then((resp) => {
        this.$emit("create-team", resp.data);
      });
    },
    skip() {
      this.$emit("skip");
    },
  },
};
</script>
