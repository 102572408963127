<template>
  <v-card class="elevation-0 my-5" color="transparent">
    <h1 class="text-xs-center mb-4">
      <v-avatar color="success" class="mr-2">
        <v-icon class="white--text">check</v-icon>
      </v-avatar>
      {{ name }}
    </h1>
    <span class="card-title">Your Invite Link</span>
    <v-layout row wrap align-center>
      <v-flex md12 lg9>
        <v-card dark>
          <v-card-text>
            <span class="invite-link" v-text="teamLink" />
          </v-card-text>
        </v-card>
      </v-flex>
      <v-flex md12 lg3>
        <v-btn
          v-clipboard:copy="teamLink"
          v-clipboard:success="onCopy"
          :color="copyColor"
          flat
          block
        >
          Copy Link
        </v-btn>
      </v-flex>
    </v-layout>
    <span>
      Anyone with this link will be able to join your team and view your team's
      connections. So be careful who you share it with. The link will expire in
      7 days for security purposes.
    </span>
    <v-btn
      :href="mailInviteLink"
      target="_blank"
      color="info"
      class="my-4"
      block
      @click="onCopy"
    >
      <v-icon class="white--text" left> mail </v-icon>
      Draft Invite Email
    </v-btn>
    <v-card-actions>
      <v-spacer />
      <v-btn :disabled="!hasInvited" color="primary" @click="next">
        Finish
      </v-btn>
      <v-btn color="primary" flat @click="skip"> Skip For Now </v-btn>
      <v-spacer />
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";

import API from "@/api/teams";
import Text from "@/text";

export default {
  name: "InviteMembers",
  props: {
    id: {
      type: String,
      default: null,
    },
    name: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      hasInvited: false,
      teamLink: "",
    };
  },
  computed: {
    ...mapGetters({
      profile: "getProfile",
    }),
    copyColor() {
      return this.hasInvited ? "grey" : "primary";
    },
    mailInviteLink() {
      return Text.mailInviteLink({
        name: this.profile.name,
        teamName: this.name,
        link: this.teamLink,
      });
    },
  },
  watch: {
    id: {
      handler() {
        this.requestLink(this.id);
      },
    },
  },
  mounted() {
    if (this.id == null) {
      return;
    }
    this.requestLink(this.id);
  },
  methods: {
    next() {
      this.$emit("continue");
    },
    requestLink(teamId) {
      API.fetchInviteLink(teamId)
        .then((resp) => {
          this.teamLink = resp.data.link;
        })
        .catch((_error) => {
          // Nothing
        });
    },
    skip() {
      this.$emit("skip");
    },
    onCopy() {
      this.hasInvited = true;
    },
  },
};
</script>

<style lang="scss">
.invite-link {
  overflow-wrap: anywhere;
  word-break: break-all;
}
</style>
