<template>
  <v-card>
    <v-data-table
      :headers="headers"
      :items="payments"
      :rows-per-page-items="[10]"
    >
      <template #items="props">
        <td>
          <span
            v-if="props.item.status === 'succeeded'"
            class="capitalize green--text"
          >
            paid
          </span>
          <span v-else class="capitalize red--text">failed</span>
        </td>
        <td>{{ props.item.date }}</td>
        <td>{{ props.item.amount }}</td>
        <td>{{ props.item.card }}</td>
        <td>{{ props.item.message }}</td>
        <td>
          <a
            class="view-receipt"
            rel="noopener noreferrer"
            target="_blank"
            :href="props.item.receipt_url"
          >
            VIEW RECEIPT
          </a>
        </td>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "PreviousPaymentsTable",
  data() {
    return {
      headers: [
        { text: "Status", value: "status", sortable: false },
        { text: "Date", value: "date", sortable: false },
        { text: "Amount", value: "amount", sortable: false },
        { text: "Card", value: "card", sortable: false },
        { text: "Message", value: "message", sortable: false },
        { text: "Receipt", value: "receipt", sortable: false },
      ],
    };
  },
  computed: {
    ...mapGetters({
      payments: "payment/getPayments",
    }),
  },
};
</script>

<style scoped>
.capitalize {
  text-transform: capitalize;
}

.view-receipt {
  text-decoration: none;
}
</style>
